//
// Button group
//

// General

.btn-group {
  .btn {
    box-shadow: none;
  }
}

// Colors

[data-toggle='buttons']:not(.btn-group-colors) {
  & > .btn {
    background-color: $gray-100;
    cursor: pointer;
    box-shadow: none;
    border: 0;
    margin: 0;

    &:not(.active) {
      color: $body-color;
    }

    &.active {
      background-color: theme-color('primary');
      color: color-yiq(theme-color('primary'));
    }
  }
}

.btn-group-colors {
  & > .btn {
    box-shadow: none;
    border-radius: 50% !important;
    width: 30px;
    height: 30px;
    padding: 0;
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
    position: relative;

    &:not([class*='bg-']) {
      border-color: $gray-100 !important;
    }

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      line-height: 28px;
      color: $white;
      transform: scale(0);
      opacity: 0;
      @include icon-font('\ea26', 14px);
      @include transition(transform 200ms, opacity 200ms);
    }

    &.btn:not([class*='bg-']) {
      border: 1px solid darken($input-border-color, 5%);

      &:before {
        color: $body-color;
      }
    }

    &.active {
      &:before {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}
