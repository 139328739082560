//
// Dismissible alert
//

.alert-dismissible {
  .close {
    top: 50%;
    right: $alert-padding-x;
    transform: translateY(-50%);
    padding: 0;
    opacity: 1;

    @include media-breakpoint-down(xs) {
      top: 1rem;
      right: 0.5rem;
    }

    & > span:not(.sr-only) {
      font-size: 1.5rem;
      background-color: transparent;
      color: rgba($white, 0.6);
    }

    &:hover,
    &:focus {
      & > span:not(.sr-only) {
        background-color: transparent;
        color: rgba($white, 1);
      }
    }
  }
}

.alert-secondary {
  .close {
    & > span:not(.sr-only) {
      color: rgba(theme-color('default'), 0.6);
    }

    &:hover,
    &:focus {
      & > span:not(.sr-only) {
        color: rgba(theme-color('default'), 1);
      }
    }
  }
}
