/* You can add global styles to this file, and also import other style files */
// input.ng-invalid{
//     border-color:red;
// }

// // input.ng-valid{
// //     border-color:green;
// // }

// input.ng-valid{
//     border-color: green;
//     ;
// }

.red-icon {
  color: red;
}

.green-icon {
  color: green;
}

.red-text {
  color: #65174a;
}

.check {
  width: 20px;
  height: 20px;
}

.alerta {
  color: white;
  background: red;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
