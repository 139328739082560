/*!

=========================================================
* Argon Dashboard PRO Angular - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-angular
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Bootstrap functions
@import 'bootstrap/functions';
@import 'bootstrap/mixins';

// Argon functions
@import 'custom/functions';
@import 'custom/variables';
@import 'bootstrap/variables';

// Argon mixins
@import 'custom/mixins';

// Bootstrap components
@import 'bootstrap/root';
@import 'bootstrap/reboot';
@import 'bootstrap/type';
@import 'bootstrap/images';
@import 'bootstrap/code';
@import 'bootstrap/grid';
@import 'bootstrap/tables';
@import 'bootstrap/forms';
@import 'bootstrap/buttons';
@import 'bootstrap/transitions';
@import 'bootstrap/dropdown';
@import 'bootstrap/button-group';
@import 'bootstrap/input-group';
@import 'bootstrap/custom-forms';
@import 'bootstrap/nav';
@import 'bootstrap/navbar';
@import 'bootstrap/card';
@import 'bootstrap/breadcrumb';
@import 'bootstrap/pagination';
@import 'bootstrap/badge';
@import 'bootstrap/jumbotron';
@import 'bootstrap/alert';
@import 'bootstrap/progress';
@import 'bootstrap/media';
@import 'bootstrap/list-group';
@import 'bootstrap/close';
@import 'bootstrap/modal';
@import 'bootstrap/tooltip';
@import 'bootstrap/popover';
@import 'bootstrap/carousel';
@import 'bootstrap/utilities';
@import 'bootstrap/print';

// Argon components
@import 'custom/components';
@import 'custom/utilities';
@import 'custom/vendors';

// Angular changes
@import 'angular/dropdowns';
@import 'angular/navbar-vertical';
@import 'angular/progressbar';
@import 'angular/plugin-ngx-chips';
@import 'angular/ngx-toastr';
@import 'angular/mobius1-selectr';
@import 'angular/tooltips';
@import 'angular/plugin-ngx-datatable';
@import 'angular/navbar-vertical';
@import 'angular/vector-map';

@import '~ngx-toastr/toastr-bs4-alert';

ngx-dropzone-remove-badge.ng-star-inserted {
  z-index: 100;
}

//ngx-select-ex
.ngx-select {
  width: 100% !important;
}

.ngx-select__item_active {
  background-color: #5e72e4 !important;
}

.ngx-select__selected-single {
  color: #8898aa !important;
}
.ngx-select__clear-icon {
  color: #8898aa !important;
}

.form-control-lg {
  height: 47px !important;
  padding-left: 10px !important;
  padding-bottom: 5px !important;
}

.select-no-valid {
  .ngx-select__toggle {
    @extend .is-invalid;
  }

  .form-control-lg {
    @extend .is-invalid;
    height: 47px !important;
    padding-left: 10px !important;
    padding-bottom: 5px !important;
  }
}

.select-valid {
  .ngx-select__toggle {
    @extend .is-valid;
  }

  .form-control-lg {
    @extend .is-valid;
    height: auto !important;
  }
}

.invalid-feedback-text {
  font-size: 80%;

  width: 100%;
  margin-top: 0.25rem;

  color: #fb6340;
}

//Para validación de formularios con iconos
.valida {
  border-color: #2dce89;
  color: #2dce89;
}

.noValida {
  border-color: #fb6340;
  color: #fb6340;
}
